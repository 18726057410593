import axios from 'axios'

class AccountService {

  constructor() {
    this.client = axios.create({
      baseURL: '/api/'
    });
  }

  getInfo() {
    const token = this.getAuthToken()

    var config = {
      headers: { 'Authorization': "bearer " + token }
    };

    return this.client.get('/account/info', config)
      .then(r => r.data)
  }

  login(email, password) {
    return this.client
      .post('/account/login', { email: email, password: password })
      .then(r => {
        this.setAuthToken(r.data.token)
      })
  }

  signOut() {
    console.log('Signing out')
    localStorage.setItem('auth-token', null)
  }

  setAuthToken(token) {
    localStorage.setItem('auth-token', token)
  }

  getAuthToken() {
    return localStorage.getItem('auth-token') || null
  }
}

const instance = new AccountService();

export default instance;
