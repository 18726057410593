import axios from 'axios';
import router from '@/router';
import AccountService from './AccountService';

class HttpWrapper {
  constructor() {
    let client = axios.create({
      baseURL: '/api'
    });

    client.interceptors.response.use((r) => r, this.handleError)

    this.client = client
  }

  handleError(error) {
    switch (error.response.status) {
      case 401:
        AccountService.signOut()
        router.push('/login')
        break;
    }
    return Promise.reject(error)
  }

  get(url) {
    const token = AccountService.getAuthToken();

    var config = {
      headers: { 'Authorization': "bearer " + token }
    };

    return this.client.get(url, config);
  }

  post(url, data) {
    const token = AccountService.getAuthToken();

    var config = {

      headers: { 'Authorization': "bearer " + token,   'Content-Type': 'application/json'}
    };

    return this.client.post(url, data, config);
  }

}

const instance = new HttpWrapper();

export default instance;


