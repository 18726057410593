<template>
  <div>
    <h3>Weight loss - Last 7 days</h3>
    <canvas ref="chart"></canvas>
  </div>
</template>
<script>
import ColorService from '@/services/ColorService';
import TeamService from '@/services/TeamService';
import Chart from 'chart.js';
export default {
  name: "WeightLossThisWeek",
  props: ["team", "members"],
  async mounted() {
    let vm = this;
    let raw = await TeamService.getThisWeek(this.team.id);
    let ordered = _.orderBy(raw, i => i.value, 'desc')


    let labels = [];
    let values = [];
    let colors = [];

    for (var i in ordered) {
      let item = ordered[i];
      labels.push(this.members[item.userId].firstName)
      values.push(item.value);
      colors.push(this.members[item.userId].color)
    }

    const ctx = this.$refs.chart

    var myBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Weight loss',
          data: values,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 3
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            minBarLength: 4,
            ticks: {
              beginAtZero: true
            }
          }]
        }

      }
    });
  }
}

</script>
