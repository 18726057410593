<template>
  <div class="container">
    <h1 v-if="team">{{team.name}}</h1>
    <div class="members" v-if="members">
      <div class="row">
        <div class="col-sm">
          <h3>Members</h3>
          <ul style="display: flex; list-style: none; justify-content: space-between;">
            <li v-for="m in members">
              {{m.firstName}}
            </li>
          </ul>
        </div>
        <div class="col-sm highlight" style="padding: 1em;">
          <h3>Log weight</h3>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label for="weight">Weight: kg</label>
              <input type="number" class="form-control" id="weight" v-model="weight" step=".01">
            </div>
            <button type="submit" class="btn btm-sm btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <div class="charts">
      <div class="row">
        <div class="col">
          <this-week v-if="team && members" :team="team" :members="members" />
        </div>
        <div class="col">
          <weight v-if="team && members" :team="team" :members="members" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <all-time v-if="team && members" :team="team" :members="members" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TeamService from '@/services/TeamService';

import ThisWeek from '@/components/weight-loss/ThisWeek.vue';
import AllTime from '@/components/weight-loss/AllTime.vue';
import Weight from '@/components/weight-loss/Weight.vue';

import _ from 'lodash';
export default {
  name: "Team",
  data() {
    return {
      team: null,
      members: null,
      weight: 0
    }
  },
  async mounted() {
    const id = this.$route.params.id
    this.team = await TeamService.getTeam(id);
    this.members = await TeamService.getMembers(id);
  },
  components: {
    ThisWeek,
    AllTime,
    Weight
  },
  methods: {
    async submit() {
      await TeamService.logWeight(this.team.id, this.weight);
      this.$router.go()
    }
  }
}

</script>
<style lang="scss" scoped>
.row {
  margin-bottom: 20px;
}

.highlight {
  background: #ddd;
}
</style>
