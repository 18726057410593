var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "jumbotron" },
      [
        _c("h1", { staticClass: "display-4" }, [_vm._v("Track Together")]),
        _vm._v(" "),
        _c("p", { staticClass: "lead" }, [
          _vm._v("Work in teams to track weightloss.")
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "my-4" }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "At present this project is in early alpha and access is granted by invitation only."
          )
        ]),
        _vm._v(" "),
        !_vm.isLoggedIn
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-primary btn-lg",
                attrs: { to: "/login" }
              },
              [_vm._v("Login")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoggedIn
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-primary btn-lg",
                attrs: { to: "/dashboard" }
              },
              [_vm._v("Dashboard")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }