<template>
  <div>
    <h3>Weight - Last 7 days</h3>
    <canvas ref="chart"></canvas>
  </div>
</template>
<script>
import TeamService from '@/services/TeamService';
export default {
  name: "Weight",
  props: ["team", "members"],
  async mounted() {
    let entries = await TeamService.getEntries(this.team.id);

    const ctx = this.$refs.chart;

    let dates = entries.dates;

    let config = {
      type: 'line',
      data: {
        labels: dates,
        datasets: []
      },
      options: {
        spanGaps: true
      }
    };

    let datasets = entries.items;
    let i = 0;

    for (var key in datasets) {
      let label = this.members[key].firstName;
      let color = this.members[key].color;
      let data = _.map(datasets[key], i => i.value);

      config.data.datasets.push({
        label: label,
        data: data,
        fill: false,
        borderColor: color,
        backgroundColor: color,
      })
    }

    var myChart = new Chart(ctx, config);

  }
}

</script>
