<template>
    <div class="container">
        <div class="jumbotron">
            <h1 class="display-4">Track Together</h1>
            <p class="lead">Work in teams to track weightloss.</p>
            <hr class="my-4">
            <p>At present this project is in early alpha and access is granted by invitation only.</p>
            <router-link to="/login" class="btn btn-primary btn-lg" v-if="!isLoggedIn">Login</router-link>
            <router-link to="/dashboard" class="btn btn-primary btn-lg" v-if="isLoggedIn">Dashboard</router-link>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import AccountService from '@/services/AccountService';
export default {
    name: "Home",
    computed: {
        isLoggedIn() {
            return !!AccountService.getAuthToken()
        }
    }
}

</script>
<style lang="scss" scoped>
.container {
    margin-top: 20px;
}

</style>
