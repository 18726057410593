import HttpWrapper from './HttpWrapper'
import _ from 'lodash';

const colors = ['#8e24aa', '#3949ab', '#fbc02d', '#dd2c00'];

class TeamService {
  getMyTeams() {
    return HttpWrapper.get('/team/my-teams')
      .then(r => r.data)
  }
  getTeam(id) {
    return HttpWrapper.get(`/team/${id}`)
      .then(r => r.data)
  }

  getMembers(id) {
    return HttpWrapper.get(`/team/${id}/members`)
      .then(r => {
        let i = 0;
        let data = _(r.data)
          .map(m => {
            m.color = colors[i++];
            return m;
          })
          .value();

          console.log(data)

        return _.keyBy(data, 'id');
      });
  }

  getEntries(id) {
    return HttpWrapper.get(`/team/${id}/entries/last-7-days`)
      .then(r => r.data)
  }

  getAllTime(id) {
    return HttpWrapper.get(`/team/${id}/entries/weight-loss/all-time`)
      .then(r => r.data)
  }

  getThisWeek(id) {
    return HttpWrapper.get(`/team/${id}/entries/weight-loss/last-7-days`)
      .then(r => r.data)
  }

   logWeight(id, weight) {
    return HttpWrapper.post(`/team/${id}/add-entry`, weight)
      .then(r => r.data)
  }
}


const instance = new TeamService();

export default instance;
