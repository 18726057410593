var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.account
      ? _c("div", [
          _c("h2", [_vm._v("Welcome back " + _vm._s(_vm.account.firstName))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.teams
      ? _c("div", { attrs: { id: "teams" } }, [
          _c("h3", [_vm._v("My Teams")]),
          _vm._v(" "),
          _vm.teams.length == 0
            ? _c("p", [
                _vm._v(
                  "\n            You are not yet a member of any teams\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.teams, function(team) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "key",
                      rawName: "v-key",
                      value: team.id,
                      expression: "team.id"
                    }
                  ],
                  staticClass: "col-sm-3"
                },
                [
                  _c(
                    "div",
                    { staticClass: "card", staticStyle: { width: "18rem" } },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("h5", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(team.name))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "card-text" }, [
                            _vm._v("members: " + _vm._s(team.memberCount))
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                to: { name: "team", params: { id: team.id } }
                              }
                            },
                            [_vm._v("View")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }