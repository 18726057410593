<template>
    <div class="container">
        <h1>Login</h1>
        <form @submit.prevent="submit">
            <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" class="form-control" id="email" aria-describedby="emailHelp" v-model="email">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="password">
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
</template>
<script>
import AccountService from '@/services/AccountService'


export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        submit(e) {
            AccountService.login(this.email, this.password)
                .then(() => {
                    this.$router.push('/dashboard')
                })
                .catch((e) => console.error(e))
        }
    }

}

</script>
<style lang="scss" scoped>
.container {
    margin-top: 20px;
}

</style>
