var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.team ? _c("h1", [_vm._v(_vm._s(_vm.team.name))]) : _vm._e(),
    _vm._v(" "),
    _vm.members
      ? _c("div", { staticClass: "members" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm" }, [
              _c("h3", [_vm._v("Members")]),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticStyle: {
                    display: "flex",
                    "list-style": "none",
                    "justify-content": "space-between"
                  }
                },
                _vm._l(_vm.members, function(m) {
                  return _c("li", [
                    _vm._v(
                      "\n            " + _vm._s(m.firstName) + "\n          "
                    )
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm highlight",
                staticStyle: { padding: "1em" }
              },
              [
                _c("h3", [_vm._v("Log weight")]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "weight" } }, [
                        _vm._v("Weight: kg")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.weight,
                            expression: "weight"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "weight", step: ".01" },
                        domProps: { value: _vm.weight },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.weight = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btm-sm btn-primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v("Submit")]
                    )
                  ]
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "charts" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _vm.team && _vm.members
              ? _c("this-week", {
                  attrs: { team: _vm.team, members: _vm.members }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col" },
          [
            _vm.team && _vm.members
              ? _c("weight", {
                  attrs: { team: _vm.team, members: _vm.members }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 offset-sm-3" },
          [
            _vm.team && _vm.members
              ? _c("all-time", {
                  attrs: { team: _vm.team, members: _vm.members }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }