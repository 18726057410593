<template>
    <div class="container">
        <div v-if="account">
            <h2>Welcome back {{account.firstName}}</h2>
        </div>
        <div id="teams" v-if="teams">
            <h3>My Teams</h3>
            <p v-if="teams.length == 0">
                You are not yet a member of any teams
            </p>
            <div class="row">
                <div class="col-sm-3" v-for="team in teams" v-key="team.id">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title">{{team.name}}</h5>
                            <p class="card-text">members: {{team.memberCount}}</p>
                            <router-link class="btn btn-primary" :to="{ name: 'team', params: { id: team.id }}">View</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import AccountService from '@/services/AccountService'
import TeamService from '@/services/TeamService'
export default {
    name: 'Dashboard',
    data() {
        return {
            account: null,
            teams: null
        }
    },
    async mounted() {
        this.teams = await TeamService.getMyTeams();
        this.account = await AccountService.getInfo(); // TODO: this wont redirect if it fails
    }
}

</script>
<style lang="scss" scoped>
.container {
    margin-top: 20px;
}

</style>
